import { Enum } from "../format";
import Log from "../log";
import { feedbackAction, uwpAction } from "../../store";

export const UploadFileStatusEnum = Enum({
  Normal: 0,
  PackageStart: 100,
  PackageEnd: 102,
  PackageFail: 104,
  GetUploadLinkStart: 200,
  GetUploadLinkEnd: 202,
  GetUploadLinkFail: 204,
  UploadFileStart: 300,
  UploadFileEnd: 302,
  UploadFileFail: 304,
  Canceled: 400,
  Canceling: 401,
  UpdateChecksumStart: 500,
  UpdateChecksumEnd: 502,
  UpdateChecksumFail: 504,
  UploadFileFinish: 900,
});

export class UwpUploadFileStatus {
  status = UploadFileStatusEnum.Normal;
  percentage = 0;
  msg = "";
}

export default class Uwp {
  static async initBridge() {
    if (window.chrome && window.chrome.webview) {
      Log.info("UwpBridge is initialized");
      window.uwpBridge =
        window.chrome.webview.hostObjects.sync.FBHUwpBridge.async();
      return true;
    }
    Log.error("UwpBridge is not initialized");
    return false;
  }

  static getBridge() {
    if (!window.uwpBridge) {
      return null;
    }
    return window.uwpBridge;
  }

  static getWebView() {
    // eslint-disable-next-line no-undef
    if (!window.chrome.webview) {
      return null;
    }
    // eslint-disable-next-line no-undef
    return window.chrome.webview;
  }

  static async getHostKeyNames() {
    const res = await Uwp.getBridge()._hostKeyNames;
    return res;
  }

  static async getLoginUserInfo() {
    try {
      const hostKeyNames = await Uwp.getHostKeyNames();
      if (hostKeyNames.indexOf("getLoginUserInfo") === -1) {
        Log.debug("[UWP] not support getLoginUserInfo");
        return null;
      }
      let res = await Uwp.getBridge().getLoginUserInfo();
      Log.debug("[UWP] getLoginUserInfo", res);
      res = res === "Unknown" ? null : JSON.parse(res);
      return res;
    } catch (e) {
      Log.error("[UWP] getLoginUserInfo", e);
      return null;
    }
  }

  static async getCurrentLanguage() {
    const res = await Uwp.getBridge().getCurrentLanguage();
    Log.debug("[UWP] getCurrentLanguage", res);
    return res;
  }

  static async getThemeType() {
    const res = await Uwp.getBridge().getThemeType();
    Log.debug("[UWP] getThemeType", res);
    return res;
  }

  static async getSubbrandName() {
    const res = await Uwp.getBridge().getSubbrandName();
    Log.debug("[UWP] getSubbrandName", res);
    return res;
  }

  static async getUwpPluginVersion() {
    const res = await Uwp.getBridge().getUwpPluginVersion();
    Log.debug("[UWP] getUwpPluginVersion", res);
    return res;
  }

  static async getDeviceSN() {
    const res = await Uwp.getBridge().getDeviceSN();
    Log.debug("[UWP] getDeviceSN", res);
    return res;
  }

  /*
   * @return {UwpUploadFileStatus}
   */
  static async getUploadLogFileStatus() {
    const tmp = await Uwp.getBridge().getUploadLogFileStatus();
    const res = JSON.parse(tmp);
    Log.debug("[UWP] getUploadLogFileStatus", res);
    return res;
  }

  /*
   * @param {string} id - feedback id
   * @return {boolean} - true if success, false if fail
   */
  static async uploadLogFile(id) {
    const res = await Uwp.getBridge().uploadLogFile(id);
    Log.debug("[UWP] uploadLogFile id=", id, ", result =", res);
    return res;
  }

  /*
   * @return {boolean} - true if success, false if fail
   */
  static async cancelUploadLogFile() {
    const res = await Uwp.getBridge().cancelUploadLogFile();
    Log.debug("[UWP] cancelUploadLogFile", res);
    return res;
  }

  static async getModelName() {
    const res = await Uwp.getBridge().getModelName();
    Log.debug("[UWP] getModelName", res);
    return res;
  }

  static async getUwpAppVersion() {
    const res = await Uwp.getBridge().getUwpAppVersion();
    Log.debug("[UWP] getUwpAppVersion", res);
    return res;
  }

  static async getOSVersion() {
    const res = await Uwp.getBridge().getOSVersion();
    Log.debug("[UWP] getOSVersion", res);
    return res;
  }

  static async getBIOSVersion() {
    const res = await Uwp.getBridge().getBIOSVersion();
    Log.debug("[UWP] getBIOSVersion", res);
    return res;
  }

  static async getUwpAppName() {
    const res = await Uwp.getBridge().getUwpAppName();
    Log.debug("[UWP] getUwpAppName", res);
    return res;
  }

  static async getServiceVersion() {
    const res = await Uwp.getBridge().getServiceVersion();
    Log.debug("[UWP] getServiceVersion", res);
    return res;
  }

  static async getAllPluginVersion() {
    const res = await Uwp.getBridge().getAllPluginVersion();
    Log.debug("[UWP] getAllPluginVersion", res);
    return res;
  }

  /*
   * @return {boolean} - true if success, false if fail
   */
  static async resetUploadLogFileStatus() {
    const res = await Uwp.getBridge().resetUploadLogFile();
    Log.debug("[UWP] resetUploadLogFile", res);
    return res;
  }

  static async intervalSync(dispatch) {
    setInterval(async () => {
      const resp = await Uwp.getUploadLogFileStatus();
      dispatch(uwpAction.updateUploadStatus(resp));
    }, 3000);
  }

  static async getFeedbackInfo(retry = 3) {
    const data = {
      sn: "",
      app_name: "",
      model_name: "",
      uwp_plugin_version: "",
      app_version: "",
      os_version: "",
      bios_version: "",
      lang: "",
      service_version: "",
      version_info: {},
    };
    if (!Uwp.getBridge()) {
      Log.debug("[UWP] getFeedbackInfo", data);
      return data;
    }
    try {
      const osVersion = Uwp.getBridge()
        .getOSVersion()
        .then((res) => {
          data.os_version = res;
        });
      const biosVersion = Uwp.getBridge()
        .getBIOSVersion()
        .then((res) => {
          data.bios_version = res;
        });
      const modelName = Uwp.getBridge()
        .getModelName()
        .then((res) => {
          data.model_name = res;
        });
      const uwpAppVersion = Uwp.getBridge()
        .getUwpAppVersion()
        .then((res) => {
          data.app_version = res;
        });
      const deviceSN = Uwp.getBridge()
        .getDeviceSN()
        .then((res) => {
          data.sn = res;
        });
      const lang = Uwp.getBridge()
        .getCurrentLanguage()
        .then((res) => {
          data.lang = res;
        });
      const uwpPlugin = Uwp.getBridge()
        .getUwpPluginVersion()
        .then((res) => {
          data.uwp_plugin_version = res;
        });
      const appName = Uwp.getBridge()
        .getUwpAppName()
        .then((res) => {
          data.app_name = res;
        });
      const serviceVersion = Uwp.getBridge()
        .getServiceVersion()
        .then((res) => {
          data.service_version = res;
        });
      const allPluginVersion = Uwp.getBridge()
        .getAllPluginVersion()
        .then((res) => {
          data.version_info = JSON.parse(res.replaceAll("\\u0000", ""));
          // eslint-disable-next-line no-undef
          data.version_info["FeedbackHubVersion"] = config.VERSION;
        });

      const waitList = [
        osVersion,
        biosVersion,
        lang,
        modelName,
        uwpAppVersion,
        deviceSN,
        lang,
        uwpPlugin,
        appName,
        serviceVersion,
        allPluginVersion,
      ];
      await Promise.all(waitList);
      Log.debug("[UWP] getFeedbackInfo", data);
      return data;
    } catch (e) {
      Log.error("[UWP] getFeedbackInfo", e);
      if (retry > 0) {
        return Uwp.getFeedbackInfo(retry - 1);
      }
    }
    return data;
  }

  static async launchUrl(url) {
    const res = await Uwp.getBridge().launchUrl(url);
    Log.debug("[UWP] launchUrl", res);
    return res;
  }

  static async redirectToMSSTore(id) {
    try {
      await Uwp.launchUrl(`ms-windows-store://pdp/?productid=9PM9DFQRDH3F`);
    } catch (e) {
      return window.open(
        "https://www.microsoft.com/store/apps/9PM9DFQRDH3F",
        "_blank"
      );
    }
  }

  static async redirectToAsusSupport() {
    try {
      await Uwp.launchUrl("https://www.asus.com/tw/support/");
    } catch (e) {
      return window.open("https://www.asus.com/tw/support/", "_blank");
    }
  }

  static async postMessage(name) {
    if (!Uwp.getWebView()) {
      return null;
    }
    return Uwp.getWebView().postMessage(name);
  }

  static async postMessageWithAdditionalObjects(name, data) {
    if (!Uwp.getWebView()) {
      return null;
    }
    return Uwp.getWebView().postMessageWithAdditionalObjects(name, data);
  }

  static async updateScreenshot(files) {
    const res = await Uwp.postMessageWithAdditionalObjects(
      "UpdateFiles",
      files
    );
    Log.debug(
      "[WebView] updateScreenshot",
      files.map((f) => f.name)
    );
    return res;
  }

  static async clearScreenShot() {
    const res = await Uwp.postMessageWithAdditionalObjects("ClearFiles", []);
    Log.debug("[WebView] clear screen shot");
    return res;
  }

  static async previewScreenshot(file) {
    const res = await Uwp.postMessageWithAdditionalObjects(
      "ShowPreviewDialog",
      [file]
    );
    Log.debug("[WebView] preview screen shot", file.name);
    return res;
  }

  static async redirectToSettingsPage() {
    const res = await Uwp.postMessage("GoBack");
    Log.debug("[WebView] redirect to settings page");
    return res;
  }

  static async webContentChanged(data) {
    if (data === null) {
      Log.debug("[WebView] clean Web Content");
      const res = await Uwp.postMessage("WebContentChange");
      return res;
    }
    Log.debug("[WebView] Web Content Change");
    const res = await Uwp.postMessageWithAdditionalObjects("WebContentChange", [
      {},
    ]);
    return res;
  }

  static async clearWebContent() {
    const res = await Uwp.webContentChanged(null);
    return res;
  }

  static async changeWebContent() {
    const res = await Uwp.webContentChanged(true);
    return res;
  }
}
